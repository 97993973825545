<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20">
        <FormItem>
          <span class="label">客户名称：</span>
          <Select clearable v-model="queryList.seach_name" filterable @on-change="customerChange($event)" class="iviewIptWidth250">
            <Option v-for="item in customerlist" :value="item.customer_name" :key="item.index">{{ item.customer_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label marginLeft50">有效期：</span>
          <DatePicker @on-change="changeTime($event, 1)" clearable format="yyyy-MM-dd" type="date" class="iviewIptWidth250" placeholder="请选择"></DatePicker>
          <span class="centeFont">至</span>
          <DatePicker @on-change="changeTime($event, 2)" clearable :options="options1" format="yyyy-MM-dd" type="date" class="iviewIptWidth250 marginRight90" placeholder="请选择" v-model="queryList.effective_date_end"></DatePicker>
        </FormItem>
        <FormItem>
          <span class="label">状态：</span>
          <Select clearable v-model="queryList.status" class="iviewIptWidth250">
            <Option v-for="(item, index) in statuList" :value="item.id" :key="index">{{ item.status }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="20">
          <span v-show="this.power.search" class="pageBtn finger btnSure" @click="search()">查询</span>
          <span v-show="this.power.insert" class="pageBtn finger btnSure marginLeft20" @click="add()" v-if="insertAble">新增</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table @on-sort-change="sortHospital" v-show="this.clickIndex === 1" :productList="listColumns" :productData="allList" :pages="pages" :total="total" @change-page="changePage" :isLoad="isLoad" totalText="条记录" :loading="isLoad"> </Table>
      <Table @on-sort-change="sortOperators" v-show="this.clickIndex === 2" :productList="OpelistColumns" :productData="operatorsList" :pages="pages" :total="total" @change-page="changePage" :isLoad="isLoad" totalText="条记录" :loading="isLoad"> </Table>
    </div>
    <!-- 新增  一级对话框 -->
    <Modal width="600" v-model="createUser" title="选择公司类型" label-position="left" @on-cancel="cancel">
      <div v-if="createUser">
        <div class="demo-spin-container">
          <Spin fix v-if="spinStatu">加载中...</Spin>
        </div>
      </div>
      <div class="adduser">
        <Row>
          <Col span="9"><span class="fonSize16px paddingLef50px">选择公司类型：</span></Col>
          <Col span="15">
            <RadioGroup v-model="usertypevalue">
              <Radio :label="usertype[0]">
                <span class="fonSize16px">医院</span>
              </Radio>
              <Radio class="marginLef20px" :label="usertype[1]">
                <span class="fonSize16px">经销商</span>
              </Radio>
            </RadioGroup>
          </Col>
        </Row>
      </div>
      <div slot="footer">
        <Row type="flex" justify="end">
          <Col span="12"> <Button type="primary" @click="sureUserType()">下一步</Button></Col>
        </Row>
      </div>
    </Modal>

    <!-- 禁用对话框 -->
    <Modal v-model="OffStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认禁用？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="turnOff">确定</span>
          <span class="pageBtn finger btnCancle" @click="OffStatus = false">取消</span>
        </div>
      </div>
    </Modal>

    <!-- 启用模态框 -->
    <Modal v-model="OnStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认启用？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="turnOn">确定</span>
          <span class="pageBtn finger btnCancle" @click="OnStatus = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'clientManage',
  components: {
    Table,
  },
  computed: {},
  data() {
    return {
      key: null,
      order: null,
      key2: null,
      order2: null,
      power: null,
      options1: {},
      // 控制禁用对话框的弹出
      OffStatus: false,
      // 控制启用对话框的弹出
      OnStatus: false,
      isLoad: true,
      // 客户名称下啦列表
      // label是现实的值数
      // value是传递的值数
      customerlist: [],
      chosedCustomer: '',
      chosedStatu: '',
      // 状态下啦列表数据
      statuList: [],
      // 用来存储form表单提交的数据
      queryList: { seach_name: '', effective_date_start: '', effective_date_end: '', status: '' },
      cityList: [
        {
          value: 'New York',
          label: 'New York',
        },
        {
          value: 'London',
          label: 'London',
        },
      ],
      navFrist: [
        {
          name: '医院',
          num: 140,
          type: 1,
        },
        {
          name: '经销商',
          num: 40,
          type: 2,
        },
      ],
      // 控制选中的是医院 还是经销商 1 医院,2经销商
      clickIndex: 1,
      // 医院列表
      listColumns: [
        {
          title: '序号',
          key: 'indexs',
          align: 'center',
          minWidth: 75,
        },
        {
          title: '客户编号',
          key: 'hospital_num',
          align: 'center',
          sortable: 'custom',
          minWidth: 105,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.hospital_num
              ),
            ])
          },
        },
        {
          title: '客户名称',
          key: 'hospitals_name',
          align: 'center',
          minWidth: 250,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'tableFont content_left',
                  on: {
                    click: () => {
                      window.localStorage.setItem('diffsave', '2')
                      this.goDetails(param.row.hospital_id)
                    },
                  },
                },
                param.row.hospitals_name
              ),
            ])
          },
        },
        {
          title: '营业执照有效期',
          key: 'hospital_business_time',
          sortable: 'custom',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.hospital_business_time
              ),
            ])
          },
        },
        {
          title: '执业许可证有效期',
          key: 'hospital_licensing_time',
          sortable: 'custom',
          align: 'center',
          minWidth: 250,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.hospital_licensing_time
              ),
            ])
          },
        },
        {
          title: '状态',
          key: 'hospital_state',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let str = param.row.hospital_state
            if (str == '正常') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableGreenFont',
                  },
                  str
                ),
              ])
            } else if (str == '禁用') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableRedFont',
                  },
                  str
                ),
              ])
            }
          },
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          minWidth: 200,
          render: (h, param) => {
            let status = param.row.operation
            // console.log(status)
            switch (status.length) {
              case 1:
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.OnStatus = true
                          this.operationRow = param.row
                        },
                      },
                    },
                    '启用'
                  ),
                ])
              case 2:
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.OffStatus = true
                          this.operationRow = param.row
                        },
                      },
                    },
                    '禁用'
                  ),
                  h(
                    'span',
                    {
                      class: 'tableFont tableLeft',
                      on: {
                        click: () => {
                          this.exportHospitalList(param.row)
                        },
                      },
                    },
                    '导出审批表'
                  ),
                ])
            }
          },
        },
      ],
      // 经销商列表
      OpelistColumns: [
        {
          title: '序号',
          key: 'indexs',
          align: 'center',
          minWidth: 55,
        },
        {
          title: '客户编号',
          align: 'center',
          key: 'distributor_num',
          sortable: 'custom',
          minWidth: 105,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.distributor_num
              ),
            ])
          },
        },
        {
          title: '客户名称',
          key: 'distributor_name',
          align: 'center',
          minWidth: 300,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'tableFont content_left',
                  on: {
                    click: () => {
                      window.localStorage.setItem('diffsave', '2')
                      this.goDetails(param.row.distributor_id)
                    },
                  },
                },
                param.row.distributor_name
              ),
            ])
          },
        },
        {
          title: '营业执照有效期',
          key: 'distributor_date_end',
          sortable: 'custom',
          align: 'center',
          minWidth: 176,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.distributor_date_end
              ),
            ])
          },
        },
        {
          title: '经营许可证有效期',
          key: 'businessallow_date_end',
          sortable: 'custom',
          align: 'center',
          minWidth: 156,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.businessallow_date_end
              ),
            ])
          },
        },
        {
          title: '备案日期',
          key: 'filing_date_end',
          sortable: 'custom',
          align: 'center',
          minWidth: 155,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.filing_date_end
              ),
            ])
          },
        },
        {
          title: '联系人',
          key: 'sale_person_name',
          align: 'center',
          minWidth: 117,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.sale_person_name
              ),
            ])
          },
        },
        {
          title: '联系电话',
          key: 'phone',
          align: 'center',
          minWidth: 170,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.phone
              ),
            ])
          },
        },
        {
          title: '状态',
          key: 'distributor_state',
          align: 'center',
          minWidth: 135,
          render: (h, param) => {
            let str = param.row.distributor_state
            if (str == '正常') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableGreenFont',
                  },
                  str
                ),
              ])
            } else if (str == '禁用') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableRedFont',
                  },
                  str
                ),
              ])
            }
          },
          width: 156,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          minWidth: 221,
          render: (h, param) => {
            let status = param.row.distributor_state
            // console.log(status)
            switch (status) {
              case '禁用':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.OnStatus = true
                          this.operationRow = param.row
                        },
                      },
                    },
                    '启用'
                  ),
                  // h(
                  //   'span',
                  //   {
                  //     class: 'tableFont tableLeft',
                  //     on: {
                  //       click: () => {
                  //         this.ship(param.row)
                  //       },
                  //     },
                  //   },
                  //   '概览'
                  // ),
                ])
              case '正常':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.OffStatus = true
                          this.operationRow = param.row
                        },
                      },
                    },
                    '禁用'
                  ),
                  h(
                    'span',
                    {
                      class: 'tableFont tableLeft',
                      on: {
                        click: () => {
                          this.exportDistList(param.row)
                        },
                      },
                    },
                    '导出审批表'
                  ),
                ])
            }
          },
        },
      ],
      // 存储医院的列表
      allList: [],
      // 经营商列表
      operatorsList: [],
      total: 1,
      pages: {
        page: 1,
        rows: 10,
      },
      date: '',
      // 控制新增对话框是否显示
      createUser: false,
      // 控制新增二级对话框
      createUser2: false,
      // 控制是否显示加载
      spinStatu: false,
      phone: 'apple',
      // 控制用户类型1 为医院，2为经销商
      usertype: [1, 2],
      // 存储选择的类型
      usertypevalue: 1,
      // 用户提交医院信息的时候绑定的数组
      hospitalform: [{}],
      // 存储禁用和启用的属性
      operationRow: '',
      searchIndex: '1',

      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  methods: {
    sortHospital(data) {
      console.log(data)
      this.key = data.key
      this.order = data.order
      this.getHospitalList()
    },
    sortOperators(data) {
      console.log(data)
      this.key2 = data.key
      this.order2 = data.order
      this.getoperatorsList()
    },
    // 医院导出审批表
    exportHospitalList(row) {
      console.log(row)
      this.$http.downFile(this.$api.exportHospital, { hospital_id: row.hospital_id }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '医院审批表' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 医院导出审批表
    exportDistList(row) {
      console.log(row)
      this.$http.downFile(this.$api.exportDistributor, { distributor_id: row.distributor_id }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '经销商审批表' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryList.effective_date_start = e
        that.queryList.effective_date_end = ''
        that.options1 = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryList.effective_date_start)
          },
        }
      } else {
        this.queryList.effective_date_end = e
      }
    },
    goDetails(id) {
      if (!this.power.modify) {
        return null
      }
      if (this.clickIndex == 1) {
        this.$router.push({
          path: '/clientManageFrame',
          query: {
            id: id,
          },
        })
      }
      if (this.clickIndex == 2) {
        this.$router.push({
          path: '/clientManageAgentFrame',
          query: {
            id: id,
          },
        })
      }
    },
    // 点击确认
    sureUserType() {
      this.createUser = false
      // 如果选择了医院端
      // 此处需要做一个区分客户信息保存按钮的事件 1单个页面保存，2四个页面保存
      window.localStorage.setItem('diffsave', '1')
      if (this.usertypevalue == 1) {
        this.$router.push('/clientManageFrame')
      } else if (this.usertypevalue == 2) {
        this.$router.push('/clientManageAgentFrame')
      }
    },
    noSure() {
      this.createUser = false
    },
    // 点击禁用确定按钮触发事件
    turnOff() {
      this.ship(this.operationRow)
    },
    ship(row) {
      if (this.clickIndex === 1) {
        let data = {
          hospital_id: row.hospital_id,
        }
        console.log(row)
        this.$http.put(this.$api.hospitalStatus, data, false).then(res => {
          console.log(res)
          this.getHospitalList()
          this.OffStatus = false
          this.OnStatus = false
        })
      } else if (this.clickIndex === 2) {
        let data = {
          distributor_id: row.distributor_id,
        }
        console.log(row)
        this.$http.put(this.$api.distributorStatus, data, false).then(res => {
          this.getoperatorsList()
          this.OffStatus = false
          this.OnStatus = false
        })
      }
    },

    // 启用触发事件
    turnOn() {
      this.ship(this.operationRow)
    },
    addOrder() {},
    // 页码改变
    changePage(e) {
      this.pages.page = e
      if (this.clickIndex === 1) {
        this.getHospitalList()
      }
      if (this.clickIndex === 2) {
        this.getoperatorsList()
      }
    },
    // 点击tab切换
    clickNav(type) {
      this.clickIndex = type
      this.pages.page = 1
      this.pages.rows = 10
      console.log(this.clickIndex)
      if (this.clickIndex === 1) {
        this.getHospitalList()
      }
      if (this.clickIndex === 2) {
        this.getoperatorsList()
      }
    },
    customerChange(e) {
      console.log(e)
      this.customerlist.forEach((item, index) => {
        if (item.customer_name == e) {
          this.searchIndex = item.customer_type
        }
      })
    },
    // 获取医院列表
    getHospitalList() {
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
        customer_type: this.searchIndex,
        seach_name: this.queryList.seach_name,
        effective_date_start: this.queryList.effective_date_start,
        effective_date_end: this.queryList.effective_date_end,
        state: this.queryList.status,
        sort_str: this.key,
        sort_type: this.order,
      }
      this.isLoad = true
      // 发送请求
      this.$http.get(this.$api.hospitalManage, data, true).then(res => {
        console.log(res)
        this.isLoad = false
        this.allList = res.data.hospitals
        this.total = res.data.total
        this.allList.forEach((item, index) => {
          // 处理列表中的时间日期
          if (item.hospital_business_time != '长期') {
            item.hospital_business_time = this.$moment.unix(item.hospital_business_time).format('YYYY-MM-DD')
            if (item.hospital_business_time == '1970-01-01') {
              item.hospital_business_time = ''
            }
          }

          if (item.hospital_licensing_time != '长期') {
            item.hospital_licensing_time = this.$moment.unix(item.hospital_licensing_time).format('YYYY-MM-DD')
            if (item.hospital_licensing_time == '1970-01-01') {
              item.hospital_licensing_time = ''
            }
          }
          // 增加序号
          item.indexs = index + 1 + (this.pages.page - 1) * 10
          // 处理空值到话用/代替
          for (var key in item) {
            if (!item[key]) {
              item[key] = '/'
            }
          }
        })
        // 获取下拉数据
        this.$http.get(this.$api.customerList, true).then(res => {
          // console.log(res)
          this.customerlist = res.data
          // console.log(this.customerlist)
        })
        this.$http.get(this.$api.statusList, true).then(res => {
          console.log(res)
          this.statuList = res.data
        })
      })
    },
    // 获取经销商列表
    getoperatorsList() {
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
        seach_name: this.queryList.seach_name,
        customer_type: this.searchIndex,
        effective_date_start: this.queryList.effective_date_start,
        effective_date_end: this.queryList.effective_date_end,
        state: this.queryList.status,
        sort_str: this.key2,
        sort_type: this.order2,
      }
      this.isLoad = true
      console.log(data)
      this.$http.get(this.$api.distributorManage, data, true).then(res => {
        this.isLoad = false
        this.operatorsList = res.data.distributors
        this.total = res.data.total
        this.operatorsList.forEach((item, index) => {
          // 处理列表中的时间日期
          if (item.distributor_date_end != '长期') {
            item.distributor_date_end = this.$moment.unix(item.distributor_date_end).format('YYYY-MM-DD')
            if (item.distributor_date_end == '1970-01-01') {
              item.distributor_date_end = ''
            }
          }
          if (item.businessallow_date_end != '长期') {
            item.businessallow_date_end = this.$moment.unix(item.businessallow_date_end).format('YYYY-MM-DD')
            if (item.businessallow_date_end == '1970-01-01') {
              item.businessallow_date_end = ''
            }
          }
          if (item.filing_date_end != '长期') {
            item.filing_date_end = this.$moment.unix(item.filing_date_end).format('YYYY-MM-DD')
            if (item.filing_date_end == '1970-01-01') {
              item.filing_date_end = ''
            }
          }
          // 增加序号
          item.indexs = index + 1 + (this.pages.page - 1) * 10
          // 处理空值到话用/代替
          for (var key in item) {
            if (!item[key]) {
              item[key] = '/'
            }
          }
        })
      })
    },
    // 查询医院列表信息
    search() {
      if (this.clickIndex == '1') {
        this.pages.page = 1
        this.pages.rows = 10
        let data = {
          page: this.pages.page,
          rows: this.pages.rows,
          search_name: this.queryList.seach_name,
          customer_type: this.searchIndex,
          effective_date_start: this.queryList.effective_date_start,
          effective_date_end: this.queryList.effective_date_end,
          state: this.queryList.status,
        }
        console.log(data)
        if (data.effective_date_start != '') {
          data.effective_date_start = this.$moment(data.effective_date_start).format('YYYY-MM-DD')
        }
        if (data.effective_date_end != '') {
          data.effective_date_end = this.$moment(data.effective_date_end).format('YYYY-MM-DD')
        }
        console.log(data)
        this.$http.get(this.$api.hospitalManage, data, true).then(res => {
          console.log(res)
          this.clickIndex = 1
          this.allList = res.data.hospitals
          this.total = res.data.total
          this.allList.forEach((item, index) => {
            // 处理列表中的时间日期
            if (item.hospital_business_time != '长期') {
              item.hospital_business_time = this.$moment.unix(item.hospital_business_time).format('YYYY-MM-DD')
              if (item.hospital_business_time == '1970-01-01') {
                item.hospital_business_time = ''
              }
            }

            if (item.hospital_licensing_time != '长期') {
              item.hospital_licensing_time = this.$moment.unix(item.hospital_licensing_time).format('YYYY-MM-DD')
              if (item.hospital_licensing_time == '1970-01-01') {
                item.hospital_licensing_time = ''
              }
            }
          })
        })
      } else if (this.clickIndex == '2') {
        this.pages.page = 1
        this.pages.rows = 10
        let data = {
          page: this.pages.page,
          rows: this.pages.rows,
          search_name: this.queryList.seach_name,
          customer_type: this.searchIndex,
          effective_date_start: this.queryList.effective_date_start,
          effective_date_end: this.queryList.effective_date_end,
          state: this.queryList.status,
        }
        if (data.effective_date_start != '') {
          data.effective_date_start = this.$moment(data.effective_date_start).format('YYYY-MM-DD')
        }
        if (data.effective_date_end != '') {
          data.effective_date_end = this.$moment(data.effective_date_end).format('YYYY-MM-DD')
        }
        console.log(data)
        this.$http.get(this.$api.distributorManage, data, true).then(res => {
          this.clickIndex = 2
          console.log(res)
          this.operatorsList = res.data.distributors
          this.total = res.data.total
          this.operatorsList.forEach((item, index) => {
            // 处理列表中的时间日期
            if (item.distributor_date_end != '长期') {
              item.distributor_date_end = this.$moment.unix(item.distributor_date_end).format('YYYY-MM-DD')
              if (item.distributor_date_end == '1970-01-01' || item.distributor_date_end == null) {
                item.distributor_date_end = ''
              }
            }

            // 处理列表中的时间日期
            if (item.filing_date_end != '长期') {
              item.filing_date_end = this.$moment.unix(item.filing_date_end).format('YYYY-MM-DD')
              if (item.filing_date_end == '1970-01-01') {
                item.filing_date_end = ''
              }
            }

            if (item.businessallow_date_end != '长期') {
              item.businessallow_date_end = this.$moment.unix(item.businessallow_date_end).format('YYYY-MM-DD')
              if (item.businessallow_date_end == '1970-01-01') {
                item.businessallow_date_end = ''
              }
            }
          })
        })
      }
    },
    add() {
      this.createUser = true
    },
    cancel() {
      console.log('cancel')
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      if (this.clickIndex === 1) {
        this.getHospitalList()
      }
      if (this.clickIndex === 2) {
        this.getoperatorsList()
      }
      sessionStorage.setItem('updataCache', '1')
    }
  },
  created() {
    this.getHospitalList()
    let str = window.sessionStorage.getItem('客户管理')
    this.power = JSON.parse(str)
    this.getRightVisibal()
  },
}
</script>

<style scoped lang="less">
.fonSize16px {
  font-size: 16px;
  color: #000;
}

.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }

  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}

.marginLeft20 {
  margin-left: 20px;
}
.marginLeft50 {
  margin-left: 50px;
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
.paddingLef50px {
  padding-left: 60px;
}
.adduser {
  padding-top: 40px;
  height: 229px;
}
.marginLef20px {
  margin-left: 20px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
